<template>
  <div class="gamebetpopbg">
    <div class="gamebetpop">
      <a class="gamebetpopclose" @click="onClose('no')"><img src="@/assets_mobile/img/bkx.png"></a>
      <h2 class="myinfopophead"><img src="@/assets_mobile/img/member_re.png"></h2>
      <p class="pointover"><strong class="bl">{{userData.memName}}</strong> {{$t('front.mypage.infoEdit')}}<br>{{$t('front.member.emptyMemPass')}}</p>
      <div class="myinfopopcon">
        <input type="text" :placeholder="userData.memId" readonly="">
        <input type="password" :placeholder="$t('front.common.password')">
      </div>
      <div class="btnwrap">
        <a class="ok" @click="onClose('pass')">{{$t('front.board.check')}}</a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InfoIn',
  props: [],
  data () {
    return {}
  },
  methods: {
    onClose (value) {
      this.$emit('close', value)
    }
  }
}
</script>

<style scoped>
.myinfopopcon {margin: 20px 30px 10px !important;}
.myinfopopcon input {font-size: 16px;}
.btnwrap {margin: 0 30px 10px !important;}
.btnwrap a {height: 40px !important;line-height: 40px !important;}
</style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
